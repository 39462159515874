import './scss/index.scss'
import Swiper from 'swiper'
import 'slick-carousel'
import 'jquery-validation'
import 'magnific-popup'
import '../lib/numscroller'

$(window).on('load', function () {
  const reviewSlider = new Swiper('#review', {
    loop: true,
    slidesPerView: 6,
    spaceBetween: 30,

    navigation: {
      nextEl: '.next',
      prevEl: '.prev'
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  })

  const reviewFullSlider = new Swiper('#review-full', {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.full-next',
      prevEl: '.full-prev'
    }
  })

  reviewSlider.on('click', function (e) {
    $('.full-review-slider').addClass('visible')
    const $el = $(e.target)
    const $clickedSlide = $el.closest('.swiper-slide')
    const clickedIndex = Number($clickedSlide.data('swiper-slide-index'))
    const $fullSlider = reviewFullSlider.$el
    const index = $fullSlider.find(`[data-swiper-slide-index="${clickedIndex}"]`).index()
    reviewFullSlider.slideTo(index, 1)
  })

  $('.js-close-full-slider').on('click', function (e) {
    e.preventDefault()
    $('.full-review-slider').removeClass('visible')
  })

  const partnersSlider = new Swiper('#partners', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 60,
    navigation: {
      nextEl: '.next-partner',
      prevEl: '.prev-partner'
    },
    breakpoints: {
      420: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1200: {
        slidesPerView: 5
      }
    }
  })

  $('#youtube').slick({
    centerMode: true,
    variableWidth: true,
    centerPadding: '26px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
          variableWidth: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          variableWidth: false
        }
      }
    ]
  })
})

$(document).ready(function () {
  $('.js-print').on('click', function () {
    window.print()
  })

  $('.js-to-callback-form').on('click', function (e) {
    e.preventDefault()
    const headerHeight = $('.js-header').outerHeight()
    $('html, body').stop().animate({
      'scrollTop': $('.js-callback-form').offset().top - headerHeight
    }, 500, 'swing', function () {
      $(document).on('scroll', onScroll)
    })
  })

  $('.js-toggle-menu').on('click', function (e) {
    e.preventDefault()
    $('.js-mobile-menu').toggleClass('show')
  })

  $('.js-calc-form').validate({
    rules: {
      sum: 'required',
      period: 'required',
      phone: 'required',
      email: {
        required: true,
        email: true
      }
    },
    messages: {
      sum: {
        required: 'Обязательно для заполнения'
      },
      period: {
        required: 'Обязательно для заполнения'
      },
      phone: {
        required: 'Обязательно для заполнения'
      },
      email: {
        required: 'Обязательно для заполнения',
        email: 'Не валидный email'
      }
    },
    errorElement: 'span',
    errorClass: 'error',
    submitHandler: function (form) {
      $('.js-progress-wrapper, .js-form-wrapper').toggleClass('hidden-section')
      const timeOut = setTimeout(() => {
        $('.js-tabs-controls li, .js-tabs .tab').removeClass('active')
        $('[data-tab="3"]').addClass('active')
      }, 3300)
      const data = $(form).serialize()
      $.ajax({
        type: 'POST',
        url: 'mail.php',
        data,
        success: () => {

        },
        error: () => {
          openPopup('.error-popup')
          clearTimeout(timeOut)
          $('.js-progress-wrapper, .js-form-wrapper').toggleClass('hidden-section')
        }
      })
    }
  })

  $('.js-callback-form').validate({
    rules: {
      name: 'required',
      phone: 'required'
    },
    messages: {
      name: {
        required: 'Обязательно для заполнения'
      },
      phone: {
        required: 'Обязательно для заполнения'
      }
    },
    errorElement: 'span',
    errorClass: 'error',
    submitHandler: function (form) {
      const data = $(form).serialize()

      $.ajax({
        type: 'POST',
        url: 'mail.php',
        data,
        success: () => {
          openPopup('.success-popup')
        },
        error: () => {
          openPopup('.error-popup')
        }
      })
    }
  })

  $('.js-order-form').validate({
    rules: {
      phone: 'required'
    },
    messages: {
      phone: {
        required: 'Обязательно для заполнения'
      }
    },
    errorElement: 'span',
    errorClass: 'error',
    submitHandler: function (form) {
      const data = $(form).serialize()

      $.ajax({
        type: 'POST',
        url: 'mail.php',
        data,
        success: () => {
          openPopup('.success-popup')
        },
        error: () => {
          openPopup('.error-popup')
        }
      })
    }
  })

  $('.js-main-form').validate({
    rules: {
      code: 'required',
      sum: 'required',
      number: 'required',
      user: 'required',
      phone: 'required',
      email: {
        required: true,
        email: true
      }
    },
    messages: {
      code: {
        required: 'Обязательно для заполнения'
      },
      sum: {
        required: 'Обязательно для заполнения'
      },
      number: {
        required: 'Обязательно для заполнения'
      },
      user: {
        required: 'Обязательно для заполнения'
      },
      phone: {
        required: 'Обязательно для заполнения'
      },
      email: {
        required: 'Обязательно для заполнения',
        email: 'Не валидный email'
      }
    },
    errorElement: 'span',
    errorClass: 'error',
    submitHandler: function (form) {
      const data = $(form).serialize()

      $.ajax({
        type: 'POST',
        url: 'mail.php',
        data,
        success: () => {
          openPopup('.success-popup')
        },
        error: () => {
          openPopup('.error-popup')
        }
      })
    }
  })

  $('.js-open-form').on('click', function(e) {
    e.preventDefault()
    openPopup('.order-popup')
  })

  $('.js-to-tab').on('click', function (e) {
    e.preventDefault()
    const data = $(this).data('totab')
    if (data == 2) {
      const sum = $('.js-calc-form').validate().element('[name="sum"]')
      const period = $('.js-calc-form').validate().element('[name="period"]')
      if (sum && period) {
        $('.js-tabs-controls li, .js-tabs .tab').removeClass('active')
        $('[data-tab="' + data + '"]').addClass('active')
      }
    } else {
      $('.js-tabs-controls li, .js-tabs .tab').removeClass('active')
      $('[data-tab="' + data + '"]').addClass('active')
    }
  })

  $('.popup-youtube, .popup-success').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: true
  })

  $(document).on('scroll', onScroll)

  $('.js-header nav a, .js-footer-nav a').on('click', function (e) {
    e.preventDefault()
    $(document).off('scroll')

    const currentHref = $(this).attr('href')

    $('.js-header nav li').each(function () {
      $(this).removeClass('active')
    })
    $(`.js-header nav a[href^="${currentHref}"`).parent().addClass('active')

    var target = this.hash
    const $target = $(target)
    const headerHeight = $('.js-header').outerHeight()
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - headerHeight
    }, 500, 'swing', function () {
      $(document).on('scroll', onScroll)
      $('.js-mobile-menu').removeClass('show')
    })
  })
})

function onScroll (event) {
  const scrollPos = $(document).scrollTop()
  // const headerHeight = $('.js-header').outerHeight()
  const windowHeight = $(window).height()
  $('.js-header nav a').each(function () {
    const currLink = $(this)
    const refElement = $(currLink.attr('href'))
    if (refElement.position().top + refElement.innerHeight() > scrollPos + windowHeight / 2 && refElement.position().top < scrollPos + windowHeight / 2) {
      $('.js-header nav li').removeClass('active')
      currLink.parent().addClass('active')
    } else {
      currLink.parent().removeClass('active')
    }
  })
}

function openPopup (popup) {
  $.magnificPopup.open({
    items: {
      src: popup,
      type: 'inline'
    }
  })
}
